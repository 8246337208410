import { Codec, string, number, nullable, GetInterface } from "purify-ts/Codec";


export const KommuneData = Codec.interface({
    muni_id: string,
    name: string,
    county: string,
    metrics: Codec.interface({
      final_placement: number,
      total_score: number,
      category_scores: Codec.interface({
        nature_protection: number,
        usage_of_funding: number
      }),
      category_placements: Codec.interface({
        nature_protection: number,
        usage_of_funding: number
      })
    }),
  });
  
  export type KommuneData = GetInterface<typeof KommuneData>;