import {useEffect, useState} from "react";

export function useIsMobile() {
    const [isMobile , setIsMobile] = useState(true);

    const handleResize = () => {
        let mediaQueryMobile = window.matchMedia(`(max-width: 503px)`);
        setIsMobile(mediaQueryMobile.matches)
    }

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [isMobile]);

    return isMobile;
}
