export type RemoteData<E, D> =
  | { type: "NOT_ASKED" }
  | { type: "LOADING" }
  | { type: "FAILURE"; error: E }
  | { type: "SUCCESS"; data: D };
  
  
export function foldRemoteData<R, E, D>(
  remoteData: RemoteData<E, D>,
  notAsked: () => R,
  loading: () => R,
  failure: (error: E) => R,
  success: (data: D) => R
): R {
  switch (remoteData.type) {
    case "NOT_ASKED":
      return notAsked();
    case "LOADING":
      return loading();
    case "FAILURE":
      return failure(remoteData.error);
    case "SUCCESS":
      return success(remoteData.data);
  }
}
