import { RemoteData } from "./remoteData";
import { array } from "purify-ts/Codec";
import { KommuneData } from "./kommuneData";

declare var process: {
  env: {
    NODE_ENV: string;
  };
};

const url = (path: string) =>
  process.env.NODE_ENV === `development`
    ? `http://localhost:5000${path}`
    : `${path}`;

export async function fetchAllKommuner(): Promise<
  RemoteData<string, KommuneData[]>
> {
  try {
    const response = await fetch(url("/kommune/alle/json/array"), {mode: "same-origin"});
    const json = await response.json();
    return array(KommuneData)
      .decode(json)
      .caseOf({
        Left: (error) =>
          ({ type: "FAILURE", error } as RemoteData<string, KommuneData[]>),
        Right: (data) =>
          ({ type: "SUCCESS", data } as RemoteData<string, KommuneData[]>),
      });
  } catch (e) {
    return { type: "FAILURE", error: `Nettverksfeil: ${e}` } as RemoteData<string, KommuneData[]>
  }
}
