import { Codec, GetInterface, string, number, nullable } from "purify-ts/Codec";
import { RemoteData } from "./remoteData";
import * as R from "remeda";
import { KommuneData } from "./kommuneData";
import { Criteria } from "./criterias";
import { Maybe, Nothing } from "purify-ts/Maybe";

// DATA TYPES




type ListMode = "view_all" | "view_filtered" | "show_above_search_result"| "show_below_search_result"

interface TopBottomState {
  kommuneData: RemoteData<string, KommuneData[]>;
  filter: Criteria;
  searchTerm: string;
  listMode: ListMode;
}

export const topBottomInitialState: TopBottomState = {
  kommuneData: { type: "NOT_ASKED" },
  filter: "total",
  searchTerm: "",
  listMode: "view_filtered"
};

// ACTIONS

type GetKommuneData = {
  type: "GetKommuneData";
  payload: RemoteData<string, KommuneData[]>;
};

type SetCriteriaFilter = { type: "SetCriteriaFilter"; payload: Criteria };

type SearchKommune = { type: "SearchKommune"; payload: string };

type ViewAll = { type: "ViewAll"; };

type TopBottomAction = GetKommuneData | SetCriteriaFilter | SearchKommune | ViewAll;

// PROP TYPES FOR JSX COMPONENTS

export type TopBottomModelProps = { model: TopBottomState };
export type TopBottomDispatchProps = {
  dispatch: React.Dispatch<TopBottomAction>;
};

// REDUCER

export function topBottomReducer(
  model: TopBottomState,
  action: TopBottomAction
): TopBottomState {
  switch (action.type) {
    case "GetKommuneData":
      return R.pipe(model, R.set("kommuneData", action.payload));
    case "SetCriteriaFilter":
      return R.pipe(model, R.set("filter", action.payload));
    case "SearchKommune":
      return R.pipe(model, R.set("searchTerm", action.payload), R.set("listMode", "view_filtered"))
    case "ViewAll":
      return R.pipe(model, R.set("searchTerm", ""), R.set("listMode", "view_all"))
  }
}
