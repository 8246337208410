import React, {useState} from "react";
import ReactDOM from "react-dom";
import CaretDown from "./caretDown"
import {
  topBottomInitialState,
  topBottomReducer,
} from "../topBottom/components/update";
import {fetchAllKommuner} from "../topBottom/components/api";
import {foldRemoteData} from "../topBottom/components/remoteData";
import {KommuneData} from "../topBottom/components/kommuneData";
import CaretUp from "./caretUp";
import {useIsMobile} from "./isMobile";

function App() {
  const [model, dispatch] = React.useReducer(
      topBottomReducer,
      topBottomInitialState
  );

  React.useEffect(() => {
    async function getKommuneData() {
      dispatch({type: "GetKommuneData", payload: {type: "LOADING"}});
      const kommuneData = await fetchAllKommuner();
      dispatch({type: "GetKommuneData", payload: kommuneData});
    }

    getKommuneData();
  }, []);
  return foldRemoteData(
      model.kommuneData,
      () => <></>,
      () => <></>,
      (error) => <></>,
      (kommuneData) => (
          <SuccessView
              kommuneData={kommuneData}
          />
      )
  );
}

function SuccessView(props: { kommuneData: KommuneData[] }) {
  const [menuHidden, setMenuHidden] = useState(true);
  const [activeCounty, setActiveCounty] = useState<string | undefined>(undefined)
  const isMobile = useIsMobile()
  const counties = Array.from(new Set(props.kommuneData.map(k => k.county))).sort((a, b)=>a.localeCompare(b, "no", {sensitivity: "accent"}));

  React.useEffect(() => {
    const pathParts = window.location.pathname.split("/")
    if (pathParts.length >= 2 && pathParts[1] === "fylke") {
      setActiveCounty(pathParts[2])
    } else setActiveCounty(undefined)
  }, []);

  return (
      <>
        <div className={"county-selector text-white"}>
          <div><a href="/"><span className="font-bold text-2xl sm:text-3xl">Naturkampen</span></a></div>
          {!isMobile &&<div><span style={{paddingRight: "4px", paddingLeft: "4px"}} className="font-bold text-2xl sm:text-3xl">-</span></div>}
          <div style={isMobile ? {marginLeft: "5px"} : {}}>
            {activeCounty ? <a href={`/fylke/${activeCounty}`}><span className="font-bold text-2xl sm:text-3xl">{decodeURI(activeCounty).toLowerCase()}</span></a>
                :
                <a href="/"><span className="font-bold text-white text-2xl sm:text-3xl">Hele Norge</span></a>
            }
            <button className={"county-menu-button"} title={"Velg fylke"} onClick={() => setMenuHidden(!menuHidden)}>{menuHidden ? <CaretDown/> : <CaretUp/>}</button>
          </div>

        </div>
        <div className={"county-menu-container"}>
          <div className={`county-menu ${menuHidden ? "" : "county-menu--visible"} ${isMobile ? "county-menu--mobile" : ""}`}>
            <ul>
              <li><a className="font-bold text-white text-xl sm:text-2xl" href={`/`}>{"Hele Norge"}</a></li>
              {counties.map(county => (
                  <li><a className="font-bold text-white text-xl sm:text-2xl" key={county} href={`/fylke/${county}`}>{decodeURI(county).toLowerCase()}</a></li>
              ))}
            </ul>
          </div>
        </div>
      </>
  );
}

ReactDOM.render(<App/>, document.getElementById("county-selector"));
